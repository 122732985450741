<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}
body {
  font-size: 16px;
  background-color: #e4f1fd;
  -webkit-font-smoothing: antialiased;
}
#footer {
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #297fca;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: -0.14px;
}
h1 {
  width: 300px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
}
a {
  width: 252px;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-decoration: underline;
  text-align: right;
  color: #ffffff;
  line-height: 25px;
}
</style>

<style lang="less" scoped>
.app {
  height: 100%;
}
</style>