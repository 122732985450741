<template>
  <div style="height:30px;background-color: #297fca;"></div>
  <div id="mine">
    <van-nav-bar :title="titleStr" />
  </div>
  <van-row>
    <van-col span="8"><img :src="tokenThumb" class="thumbimg" style="object-fit: cover;" /></van-col>
    <van-col span="16">
      <van-row justify="space-around">
        <van-col span="6">
          <p>{{ tokenShare }}</p>
        </van-col>
        <van-col span="6">
          <p>{{ tokenShareIn }}</p>
        </van-col>
        <van-col span="6">
          <p>{{ tokenAdopts }}</p>
        </van-col>
      </van-row>
      <van-row justify="space-around" style="color:black;font-size:10px;">
        <van-col span="6">分享</van-col>
        <van-col span="6">浏览</van-col>
        <van-col span="6">领养</van-col>
      </van-row>
      <van-row justify="space-around" style="margin-top:10px">
        <van-col span="22">
          <van-button square type="primary" size="large" color="#3fa2f7" @click="clickToShare" style="margin-top:5px">
            分享领养
          </van-button>
        </van-col>
      </van-row>
    </van-col>
  </van-row>

  <van-row v-for="info in adoptsTokenInfo">
    <van-col span="8"><img :src="getImageUrl(info.thumburl)" class="thumbimg_follow"
        style="object-fit: cover;margin-top:5px" /></van-col>
    <van-col span="16">
      <van-row justify="end"
        style="color:#cccccc; font-size:10px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin-right:10px;margin-top:2px">
        {{ unixTime(info.createtime) }}
      </van-row>
      <van-row justify="left"
        style="color:black; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">
        {{ info.nickname }}
      </van-row>
      <van-row justify="left"
        style="color:black; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">
        编号：#{{ info.tokenid }}，稀有度：{{ info.tokenrarity }}
      </van-row>
      <van-row justify="left"
        style="color:black; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">
        分享：{{ info.shares }}，浏览：{{ info.shareins }}，领养：{{ info.adopts }}
      </van-row>
    </van-col>
    <div style="width:100%; border: .1px solid #eeeeee;"></div>
  </van-row>

  <van-action-bar style="padding:50px">
    <van-action-bar-button color="#3FA2F7" type="primary" icon="down" to="/download" />
    <van-action-bar-button color="#3FA2F7" type="primary" icon="contact" to="/" />
    <van-action-bar-button color="#3FA2F7" type="primary" icon="share-o" />
  </van-action-bar>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";
import Cookies from "js-cookie";

export default {
  setup() {
    const router = useRouter();
    //const nickname = window.sessionStorage.getItem("nickname");
    //const tokenId = window.sessionStorage.getItem("tokenId");
    //const userId = window.sessionStorage.getItem("userId");
    const nickname = Cookies.get("nickname");
    const tokenId = Cookies.get("tokenId");
    const userId = Cookies.get("userId");
    const image = require('../assets/mashowu.png');
    const tokenThumb = ref(image);
    const tokenShare = ref(0);
    const tokenShareIn = ref(0);
    const tokenAdopts = ref(0);
    const adoptsTokenInfo = ref([]);

    const titleStr = ref(nickname + "，#" + tokenId);

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const clickToShare = () => {
      router.push({ name: "toshare" });
    }

    const getImageUrl = (url) => {
      return "https://" + url;
    }

    const addZero = (m) => {
      return m < 10 ? '0' + m : m;
    }

    const unixTime = (timeunix) => {
      const time = new Date(timeunix * 1000);
      const y = time.getFullYear();
      const m = time.getMonth() + 1;  //getMonth获取的月份为（0--11），实际月份需要+1得出
      const d = time.getDate();
      const h = time.getHours();
      const mm = time.getMinutes();
      const s = time.getSeconds();
      const realtime = y + '-' + addZero(m) + '-' + addZero(d) + ' ' + addZero(h) + ':' + addZero(mm) + ':' + addZero(s);
      return realtime;
    }

    onMounted(async () => {
      let result = await postRequest(apiUrl.getUserShareInfo, {
        userId: userId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokenThumb.value = getImageUrl(data.userTokenInfo.thumburl);
        tokenShare.value = data.userTokenInfo.shares;
        tokenShareIn.value = data.userTokenInfo.shareins;
        tokenAdopts.value = data.userTokenInfo.adopts;
        adoptsTokenInfo.value = data.userAdoptsTokenInfo;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    })

    return {
      titleStr,
      tokenThumb,
      tokenShare,
      tokenShareIn,
      tokenAdopts,
      adoptsTokenInfo,
      clickToShare,
      getImageUrl,
      unixTime
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 20px;
}

.van-action-bar {
  background-color: transparent;
}

.van-button--square {
  border-radius: 5px;
}

.van-button--large {
  height: 45px;
}

#footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #297fca;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.14px;
}

.thumbimg {
  margin: 10px;
  width: 110px;
  height: 110px;
}

.thumbimg_follow {
  width: 88px;
  height: 88px;
}

p {
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: center;
  color: #000000;
  letter-spacing: -0.08px;
  height: 10px;
}
</style>