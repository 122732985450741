
export default {
    //BASE_URL: "https://tma.pixeln.cn/",        // 基本地址 测试
    BASE_URL: "https://mashowu.pixeln.cn/",        // 基本地址 正式
    login: "api/msy/login",                     // ⽤户登录
    sendCode: "api/msy/sendCode",               // 发送验证码
    getTokenInfo: "api/msy/getTokenInfo",       // 获取token详细信息
    getTalkTokenInfo: "api/msy/getTalkTokenInfo",   // 获取聊天token详细信息
    getUserInfo: "api/msy/getUserInfo",         // 获取用户的详细信息
    getChoiceTokens: "api/msy/getChoiceTokens", // 获取可选的token信息
    setChoiceToken: "api/msy/setChoiceToken",   // 设置选择的token
    getUserShareInfo: "api/msy/getUserShareInfo",     // 获取用户的分享信息
    getShareInTokenInfo: "api/msy/getShareInTokenInfo", // 获取分享页的用户相关信息
    getDesignerTokenInfo: "api/msy/getDesignerTokenInfo", // 获取创世马的相关信息
    setShareLogs: "api/msy/setShareLogs",       // 设置分享记录
    chat: "api/msy/chat",                       // 聊天(GPT3)
    chatTurbo: "api/msy/chatTurbo",             // 聊天(chatGPT)
    chatTurboInChatGLM: "api/msy/chatTurboInChatGLM",   // 聊天(chatGLM)
    chatAvatarTurbo: "api/msy/chatAvatarTurbo", // 角色聊天(chatGPT)
    chatBaikeTurbo: "api/msy/chatBaikeTurbo",   // 百科聊天(chatGPT)
    getMBInfo: "api/msy/getMBInfo",             // 获得MB信息
    feedToken: "api/msy/feedToken",             // 喂马
    getRelationInfo: "api/msy/getRelationInfo", // 获取关系指数
    setRelationInfo: "api/msy/setRelationInfo", // 设置关系指数
    getChatLogs: "api/msy/getChatLogs",         // 获取聊天记录
    getAvatarInfo: "api/msy/getAvatarInfo",     // 获取角色信息
    getAvatarInfoById: "api/msy/getAvatarInfoById", // 获取单个角色信息
    getAvatarChatLogs: "api/msy/getAvatarChatLogs", // 获取与角色的聊天记录
    getBaikeInfo: "api/msy/getBaikeInfo",       // 获取百科信息
    getBaikeInfoById: "api/msy/getBaikeInfoById", // 获取单个百科信息
    getBaikeChatLogs: "api/msy/getBaikeChatLogs", // 获取与百科的聊天记录
    setModel: "api/msy/setModel",               // 设置大模型
}

