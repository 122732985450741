<template>
  <div style="height:15px;background-color: #297fca;"></div>
  <div id="mine">
    <van-nav-bar :title="titleStr" left-arrow @click-left="onClickLeft" />
  </div>
  <van-row @click="ClickToChatIn(myTokenId)">
    <van-col span="8"><img :src="tokenThumb" class="thumbimg_follow"
        style="object-fit: cover;margin-top: 10px;margin-bottom: 5px;" /></van-col>
    <van-col span="16">
      <van-row justify="left"
        style="color:#297fca; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;margin-top: 10px;">
        #{{ myTokenId }}
      </van-row>
      <van-row justify="left"
        style="color:#297fca; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">
        我的宠物马，也是我的AI助手。
      </van-row>
      <van-row justify="left"
        style="color:#297fca; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">

      </van-row>
    </van-col>
    <div style="width:100%; border: .1px solid #eeeeee;"></div>
  </van-row>
  <div style="height:23px;background-color: #297fca;">
    <van-row>
      <van-col span="8" justify="space-between">本周人物</van-col>
      <van-col span="12" offset="4" justify="space-between"></van-col>
    </van-row>
  </div>
  <van-row v-for="info in avatarInfo" @click="ClickToAvatar(info.id)">
    <van-col span="8"><img :src="info.image" class="thumbimg_follow"
        style="object-fit: cover;margin-top: 10px;margin-bottom: 5px;" /></van-col>
    <van-col span="16">
      <van-row justify="left"
        style="color:#297fca; font-size:16px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;margin-top: 10px;">
        {{ info.name }}
      </van-row>
      <van-row justify="left"
        style="color:#297fca; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">
        {{ info.description }}
      </van-row>
      <van-row justify="left"
        style="color:#297fca; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">

      </van-row>
    </van-col>
    <div style="width:100%; border: .1px solid #eeeeee;"></div>
  </van-row>
  <div style="height:23px;background-color: #297fca;">
    <van-row>
      <van-col span="8" justify="space-between">百科大全</van-col>
      <van-col span="12" offset="4" justify="space-between"></van-col>
    </van-row>
  </div>
  <van-row v-for="info in baikeInfo" @click="ClickToBaike(info.id)">
    <van-col span="8"><img :src="info.image" class="thumbimg_follow"
        style="object-fit: cover;margin-top: 10px;margin-bottom: 5px;" /></van-col>
    <van-col span="16">
      <van-row justify="left"
        style="color:#297fca; font-size:16px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;margin-top: 10px;">
        {{ info.name }}
      </van-row>
      <van-row justify="left"
        style="color:#297fca; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">
        {{ info.description }}
      </van-row>
      <van-row justify="left"
        style="color:#297fca; font-size:14px; font-family: PingFangSC, PingFangSC-Medium;font-weight: 400;margin: 3px;">

      </van-row>
    </van-col>
    <div style="width:100%; border: .1px solid #eeeeee;"></div>
  </van-row>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";
import Cookies from "js-cookie";

export default {
  setup() {
    const router = useRouter();
    //const nickname = window.sessionStorage.getItem("nickname");
    //const tokenId = window.sessionStorage.getItem("tokenId");
    //const userId = window.sessionStorage.getItem("userId");
    const nickname = Cookies.get("nickname");
    const tokenId = Cookies.get("tokenId");
    const userId = Cookies.get("userId");
    const image = require('../assets/mashowu.png');
    const myTokenId = ref(tokenId);
    const tokenThumb = ref(image);
    const desingerTokenInfo = ref([]);
    const avatarInfo = ref([]);
    const baikeInfo = ref([]);

    const titleStr = ref(nickname + "，#" + tokenId);

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const getImageUrl = (url) => {
      return "https://" + url;
    }

    const onClickLeft = () => history.back();

    const ClickToChatIn = (tokenId) => {
      router.push({ name: "chatin", query: { tokenid: tokenId } });
    }

    const ClickToChatOut = (tokenId) => {
      router.push({ name: "chatout", query: { tokenid: tokenId } });
    }

    const ClickToAvatar = (id) => {
      router.push({ name: "chatavatar", query: { avatarid: id } });
    }

    const ClickToBaike = (id) => {
      router.push({ name: "chatbaike", query: { baikeid: id } });
    }

    const getTokenInfo = async () => {
      let result = await postRequest(apiUrl.getTokenInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokenThumb.value = "https://" + data.thumburl;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    }

    const getDesiangerTokenInfo = async () => {
      let result = await postRequest(apiUrl.getDesignerTokenInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        desingerTokenInfo.value = data;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    }

    const getAvatarInfo = async () => {
      let result = await postRequest(apiUrl.getAvatarInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        //console.log(result.data);
        avatarInfo.value = data;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    }

    const getBaikeInfo = async () => {
      let result = await postRequest(apiUrl.getBaikeInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        baikeInfo.value = data;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    }

    onMounted(async () => {
      await getTokenInfo();
      await getAvatarInfo();
      await getBaikeInfo();
      // await getDesiangerTokenInfo();
    })

    return {
      titleStr,
      myTokenId,
      tokenThumb,
      desingerTokenInfo,
      avatarInfo,
      baikeInfo,
      getImageUrl,
      ClickToChatIn,
      ClickToChatOut,
      ClickToAvatar,
      ClickToBaike,
      onClickLeft,
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
  height: 55px;
}

#mine :deep(.van-nav-bar__content) {
  height: 55px !important;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 22px;
}

#mine :deep(.van-nav-bar__right) {
  color: white !important;
  font-size: 18px;
}

#mine :deep(.van-nav-bar__arrow) {
  color: white !important;
  font-size: 22px;
}

.van-action-bar {
  background-color: transparent;
}

.van-button--square {
  border-radius: 5px;
}

.van-button--large {
  height: 45px;
}

#footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #297fca;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.14px;
}

.thumbimg {
  margin: 20px;
  width: 110px;
  height: 110px;
}

.thumbimg_follow {
  width: 66px;
  height: 66px;
}

p {
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: center;
  color: #000000;
  letter-spacing: -0.08px;
  height: 10px;
}
</style>