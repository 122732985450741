<template>
  <div style="height:15px;background-color: #297fca;"></div>
  <div id="mine" class="vant-hairline--bottom">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <van-image width="50px" height="50px" fit="cover" :src="tokenThumb" />
      </template>
      <template #right>
        {{ totalMB }}MB
      </template>
    </van-nav-bar>
  </div>
  <div style="text-align: center;color: #297fca;">
    <span style="text-color:#297fca; font-size: small;">现在可以开始聊天了</span>
  </div>
  <div ref="messageList" class="message-list" id="box">
    <ul ref="msglist" class="chat-message">
      <li v-for="(v, i) in chatList" :key="i">
        <div v-if="v.type === 'ai'" class="vchat-messageItem mes-box ai">
          <div class="messageInfo">
            <p class="mesai">
              {{ v.message }}
            </p>
          </div>
          <div style="margin-top: 10px;"><van-image class="imgCopy" :data-clipboard-text="v.message" width="30px"
              height="30px" fit="cover" :src="copyImage" @click="copyText()" /></div>
        </div>
        <div v-if="v.type === 'me'" class="vchat-messageItem mes-box me">
          <div class="messageInfo">
            <p class="mesme">
              {{ v.message }}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="footer-containter van-hairline--top">
    <van-cell-group inset>
      <van-field ref="inputText" v-model="message" name="message" clearable autosize placeholder="请输入聊天内容..."
        maxlength="300" :rules="[{ required: true, message: '内容不能为空' }]">
        <template #button>
          <van-button size="small" block type="primary" color="#297fca" :disabled="sendFlag" style="border: none"
            @click="sendMessage">
            <span style="color: #FFFFFF;"> 发送 </span></van-button>
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted, ref, watch, nextTick } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";
import Cookies from "js-cookie";
import ClipboardJS from 'clipboard';

export default {
  setup() {
    const router = useRouter();
    //const tokenId = window.sessionStorage.getItem("tokenId");
    //const nickname = window.sessionStorage.getItem("nickname");
    //const userId = window.sessionStorage.getItem("userId");
    const nickname = Cookies.get("nickname");
    const tokenId = Cookies.get("tokenId");
    const userId = Cookies.get("userId");
    const image = require('../assets/mashowu.png');
    const tokenThumb = ref(image);
    const cImage = require('../assets/copy.png');
    const copyImage = ref(cImage);
    const message = ref("");
    const sendFlag = ref(true);
    const chatList = ref([]);
    const msglist = ref();
    let talkTokenId = 0;
    const inputText = ref();
    const totalMB = ref(0);

    let sessionId = '';

    // Get the token id from url
    if (JSON.stringify(router.currentRoute._value.query) != "{}") {
      talkTokenId = router.currentRoute._value.query.tokenid;
      // console.log("Got the Token:", talkTokenId);
    }

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const onClickLeft = () => history.back();

    const copyText = () => {
      Dialog.alert({
        title: "马上友",
        message: "聊天内容已经复制到剪贴板",
      }).then(() => {
        // Do nothing
      });
    }

    const handleCopy = () => {
      const clipboard = new ClipboardJS('.imgCopy');
      clipboard.on('success', function (e) {
        /*
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);*/
        e.clearSelection();
      });

      clipboard.on('error', function (e) {
        console.log(e);
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
      });
    }

    const generateSessionId = () => {
      return Math.random().toString(16).slice(2);
    }

    // 获取MB信息
    const getMBInfo = async () => {
      try {
        let result = await postRequest(apiUrl.getMBInfo, {
          userId: userId,
          tokenId: tokenId
        });
        // 处理返回结果
        if (result.data.code == 200) {
          const data = result.data.data;
          // console.log(result.data);
          totalMB.value = data.mb;
        } else {
          // 打印出错信息
          console.log(result.data.msg);
          // 跳转到login，重新走login流程
          Dialog.alert({
            title: "马上友",
            message: result.data.msg,
          }).then(() => {
            // to Login
            router.push({ name: "login" });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    // 喂一次马，并获取最新MB信息
    const feedToken = async () => {
      try {
        let result = await postRequest(apiUrl.feedToken, {
          userId: userId,
          tokenId: tokenId
        });
        // 处理返回结果
        if (result.data.code == 200) {
          const data = result.data.data;
          // console.log(result.data);
          totalMB.value = data.mb;
        } else {
          // 打印出错信息
          console.log(result.data.msg);
          // 跳转到login，重新走login流程
          Dialog.alert({
            title: "马上友",
            message: result.data.msg,
          }).then(() => {
            // to Login
            router.push({ name: "login" });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    const getChatLogs = async () => {
      try {
        let result = await postRequest(apiUrl.getChatLogs, {
          userId: userId,
          tokenId: talkTokenId
        });
        // 处理返回结果
        if (result.data.code == 200) {
          const data = result.data.data;
          // console.log(result.data);
          // 没有数据表明是第一次聊天
          if (data.length == 0) return false;
          else {
            // 获取sessionId
            sessionId = data[0].sessionid;
            // 把记录放到上去
            let info = {};
            for (let i = 0; i < data.length; i++) {
              if (data[i].type == "completion") {
                info = {
                  "type": "ai",
                  "message": data[i].message
                }
              } else {
                info = {
                  "type": "me",
                  "message": data[i].message
                }
              }
              chatList.value.push(info);
            }
            return true;
          }
        } else {
          // 打印出错信息
          console.log(result.data.msg);
          // 跳转到login，重新走login流程
          Dialog.alert({
            title: "马上友",
            message: result.data.msg,
          }).then(() => {
            // to Login
            router.push({ name: "login" });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    const sendMessage = async () => {
      if (message.value.trim() == '') {
        message.value = '';
        return;
      }

      // 判断MB是否足够
      if (totalMB.value < 2) {
        Dialog.alert({
          title: "马上友",
          message: "MB已经不足，请获取更多MB。",
        }).then(() => {
        });
        return;
      }
      // 投喂一次
      await feedToken();

      const prompt = message.value;

      let info = {
        "type": "me",
        "message": message.value,
      }
      chatList.value.push(info);
      message.value = '';

      try {
        let index = 0;
        let startFlag = true;
        let answer = '';

        // console.log("prompt:", prompt);
        await chat(prompt, (text) => {
          if (!text) return;

          if (startFlag) {
            let info = {
              "type": "ai",
              "message": text
            }
            chatList.value.push(info);
            index = chatList.value.length - 1;
            startFlag = false;
          } else {
            chatList.value[index].message += text;
          }
          answer += text;
        });
        // console.log("answer:", answer);
        //inputText.value.focus();
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async () => {
      // 初始化复制剪贴板
      handleCopy();
      //inputText.value.focus();
      await getMBInfo();
      let result = await postRequest(apiUrl.getTalkTokenInfo, {
        userId: userId,
        tokenId: tokenId,
        talkTokenId: talkTokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokenThumb.value = "https://" + data.thumburl;
        if (await getChatLogs() == false) {
          sessionId = generateSessionId();
          await startChat();
        }
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    })

    let startChat = async () => {
      try {
        let index = 0;
        let startFlag = true;
        let prompt = '';
        // let answer = '';

        await chat(prompt, (text) => {
          if (!text) return;

          if (startFlag) {
            let info = {
              "type": "ai",
              "message": text
            }
            chatList.value.push(info);
            index = chatList.value.length - 1;
            startFlag = false;
          } else {
            chatList.value[index].message += text;
          }
          // answer += text;
        });
        // console.log("answer:", answer);
      } catch (error) {
        console.log(error);
      }
    }

    let chat = async (prompt, progress) => {
      try {
        let data = {
          "userId": userId,
          "tokenId": talkTokenId,
          "sessionId": sessionId,
          "nickname": nickname,
          "prompt": prompt,
          "type": "out"
        }
        let response = await fetch(apiUrl.BASE_URL + apiUrl.chatTurbo, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })

        const reader = response.body.getReader();
        for (; ;) {
          let result = await reader.read();
          // console.log( "result:", result );
          if (result.done) {
            progress && progress(null);
            return;
          }

          let lines = (new TextDecoder().decode(result.value)).split("\n").filter(s => s.trim() !== '');
          // console.log("lines:", lines);
          for (let line of lines) {
            // console.log( "line:", line );
            if (!line) continue;

            if (JSON.parse(line).message == "[DONE]") continue;

            let text = JSON.parse(line).message;
            if (text == '\n') continue;
            progress && progress(text);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    // 监控输入来让发送按钮是否可以点击
    watch(
      message,
      (newval, oldval) => {
        if (message._value === '') {
          sendFlag.value = true;
        } else {
          sendFlag.value = false;
        }
      }
    )

    // 实现文字出现后自动到底部
    watch(
      chatList,
      (newval, oldval) => {
        nextTick(() => {
          msglist.value.scrollTop = msglist.value.scrollHeight;
        });
      },
      {
        deep: true
      }
    )

    return {
      tokenThumb,
      copyImage,
      totalMB,
      message,
      sendFlag,
      chatList,
      onClickLeft,
      copyText,
      sendMessage,
      msglist,
      inputText
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
  height: 55px;
}

#mine :deep(.van-nav-bar__content) {
  height: 55px !important;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 22px;
}

#mine :deep(.van-nav-bar__right) {
  color: white !important;
  font-size: 18px;
}

#mine :deep(.van-nav-bar__arrow) {
  color: white !important;
  font-size: 22px;
}

:deep(.van-field__button) {
  width: 60px;
}

:deep(.van-cell-group--inset) {
  margin: 0 5px;
}

.van-cell {
  padding: 10px 10px;
}

.footer-containter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
}

.message-list {
  overflow-y: auto;
  height: calc(100% - 142px);

  .chat-message {
    overflow-y: auto;
    position: absolute;
    box-sizing: border-box;
    padding: 2px 8px;
    top: 92px;
    bottom: 50px;
    left: 0;
    right: 0;
    width: 100%;

    background-color: #FFFFFF;

    li {
      width: 100%;
      margin: 12px 0 -4px 0;
      text-align: center;
      color: #297fca;
    }
  }
}

.mes-box {
  width: 100%;
  display: flex;

  .messageInfo {
    max-width: 350px;

    p.mesai {
      margin-bottom: 0;
      margin-top: 8px;
      background-color: #e4f1fd;
      border-radius: 5px;
      padding: 8px;
      box-sizing: border-box;
      position: relative;
      text-align: left;
      word-wrap: break-word;
      font-size: 15px;
      line-height: 1.2;
      word-break: break-all;
      display: inline-block;
      color: #297FCA;
      white-space: pre-line;
    }

    p.mesme {
      margin-bottom: 0;
      margin-top: 8px;
      background-color: #297FCA;
      border-radius: 5px;
      padding: 8px;
      box-sizing: border-box;
      position: relative;
      text-align: left;
      word-wrap: break-word;
      font-size: 15px;
      line-height: 1.2;
      word-break: break-all;
      display: inline-block;
      color: #FFFFFF;
      white-space: pre-line;
    }
  }
}


.ai.mes-box .messageInfo {
  text-align: left;
  margin-left: 10px;
}

.ai.mes-box {
  justify-content: flex-start;
}

.me.mes-box {
  justify-content: flex-end;
}

.me.mes-box .messageInfo {
  text-align: right;
  margin-right: 10px;
}

.me.mes-box {
  justify-content: flex-end;
}

.ai p.mesai:before,
.me p.mesme:after {
  width: 0;
  height: 0;
  content: '';
  display: block;
  position: absolute;
  top: 10px;
}

.ai p.mesai:before {
  left: -11px;
  border-bottom: 5px solid #e4f1fd;
  border-left: 10px solid transparent;
  border-right: 5px solid transparent;
  border-top: 0;
  transform: rotate(45deg);
}

.me p.mesme:after {
  right: -10px;
  border-bottom: 5px solid #297fca;
  border-left: 5px solid transparent;
  border-right: 10px solid transparent;
  transform: rotate(-45deg);
}
</style>