// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Nickname from '../views/Nickname.vue';
import Choice from '../views/Choice.vue';
import Success from "../views/Success.vue";
import Download from "../views/Download.vue";
import Share from "../views/Share.vue";
import ToShare from "../views/ToShare.vue";
import ShareIn from "../views/ShareIn.vue";
import ChatIn from "../views/ChatIn.vue";
import ChatOut from "../views/ChatOut.vue";
import My from "../views/My.vue";
import ChatList from "../views/ChatList.vue";
import EarnMB from "../views/EarnMB.vue";
import Cookies from 'js-cookie';
import ChatAvatar from "../views/ChatAvatar.vue";
import ChatBaike from "../views/ChatBaike.vue";

// createRouter 创建路由实例
const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      name: 'login',
      path: '/login',
      component: Login,
      meta: {
        title: '马上友 - 登录'
      }
    },
    {
      name: 'home',
      path: '/',
      component: Home,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 个人中心'
      }
    },
    {
      name: 'my',
      path: '/my',
      component: My,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 个人中心'
      }
    },
    {
      name: 'earnmb',
      path: '/earnmb',
      component: EarnMB,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 个人中心'
      }
    },
    {
      name: 'download',
      path: '/download',
      component: Download,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 个人中心'
      }
    },
    {
      name: 'share',
      path: '/share',
      component: Share,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 个人中心'
      }
    },
    {
      name: 'toshare',
      path: '/toshare',
      component: ToShare,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 个人中心'
      }
    },
    {
      name: 'sharein',
      path: '/sharein',
      component: ShareIn,
      meta: {
        title: '马上友 - 领养活动'
      }
    },
    {
      name: 'chatlist',
      path: '/chatlist',
      component: ChatList,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 一起聊聊'
      }
    },
    {
      name: 'chatin',
      path: '/chatin',
      component: ChatIn,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 一起聊聊'
      }
    },
    {
      name: 'chatout',
      path: '/chatout',
      component: ChatOut,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 一起聊聊'
      }
    },
    {
      name: 'chatavatar',
      path: '/chatavatar',
      component: ChatAvatar,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 一起聊聊'
      }
    },
    {
      name: 'chatbaike',
      path: '/chatbaike',
      component: ChatBaike,
      meta: {
        needLogin: true,
        hashNFT: true,
        title: '马上友 - 一起聊聊'
      }
    },
    {
      name: 'nickname',
      path: '/nickname',
      component: Nickname,
      meta: {
        needLogin: true,
        title: '马上友 - 领养活动'
      }
    },
    {
      name: 'choice',
      path: '/choice',
      component: Choice,
      meta: {
        needLogin: true,
        title: '马上友 - 领养活动'
      }
    },
    {
      name: 'success',
      path: '/success',
      component: Success,
      meta: {
        needLogin: true,
        title: '马上友 - 领养成功'
      }
    }
  ]
});

// 进入页面前的处理
router.beforeEach((to, from, next) => {
  /*
  // 增加百度统计器
  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?33d5b74236236209495b4d8e2ade12b7";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);*/

  // 路径的判断
  if (to.path == '/login') return next();

  // login判断
  if (to.meta.needLogin) {
    //const token = window.sessionStorage.getItem("token");
    const token = Cookies.get("token");
    if (!token) return next({ path: "/login", query: { redirect: to.fullPath } });
  }

  // 增加去领养前的判断，必须要有设置昵称，否则返回设置昵称页
  // 并且防止已经拥有token的用户重入choice
  if (to.path == '/choice') {
    //const tokenID = window.sessionStorage.getItem("tokenId");
    const tokenID = Cookies.get("tokenId");
    if (!tokenID) {
      // const userNickname = window.sessionStorage.getItem("nickname");
      const userNickname = Cookies.get("nickname");
      if (!userNickname) return next("/nickname");
    } else {
      return next("/");
    }
  }

  // 防止已经拥有token的用户重入nickname
  if (to.path == '/nickname') {
    //const tokenID = window.sessionStorage.getItem("tokenId");
    const tokenID = Cookies.get("tokenId");
    if (!tokenID) return next();
    else return next("/");
  }

  // NFT判断
  if (to.meta.hashNFT) {
    //const tokenID = window.sessionStorage.getItem("tokenId");
    const tokenID = Cookies.get("tokenId");
    if (!tokenID) return next("/nickname");
  }
  // 正常路径
  next();
});

// 进入页面后的处理
router.afterEach((to, from, next) => {
  // 设置页面的title
  window.document.title = to.meta.title;
  // 每个页面回到顶部
  window.scrollTo(0, 0);
});

// 抛出路由实例, 在 main.js 中引用
export default router