import { createApp } from 'vue';
import App from './App.vue';
import { Form, Field, CellGroup, Button, Space, NavBar, ActionBar, ActionBarIcon, ActionBarButton, Col, Row,Icon, Progress, Popup, RadioGroup, Radio, Cell } from 'vant';           // 按需加载控件
import { Image as VanImage } from 'vant';                                       // 加载图片空间
import 'vant/lib/index.css';                                                    // 全局引入样式
import router from './router';                                                  // 加载页面路由

const app = createApp(App);                                                     // 创建实例

app.use(Space);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Button);
app.use(NavBar);
app.use(VanImage);
app.use(ActionBar);
app.use(ActionBarIcon);
app.use(ActionBarButton);
app.use(Col);
app.use(Row);
app.use(Icon);
app.use(Progress);
app.use(Popup);
app.use(Radio);
app.use(RadioGroup);
app.use(Cell);
app.use(router);

app.mount('#app');