<template>
  <div style="margin-top: 30px; margin-bottom: 10px">
    <van-image width="300" height="300" fit="cover" :src="require('../assets/mashowu.png')" />
  </div>
  <div>
    <p>
      「马上友」是一个全民参与，共同创造流行文化的社区。我们一同分享社区增长带来的利益，一起参与社区的治理活动，达致共识、共创、共享、共治的状态。
    </p>
  </div>
  <div>
    <h3>让你的朋友一看就知道你是谁</h3>
  </div>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
      <van-field v-model="userNickname" name="userNickname" autosize placeholder="昵称" rows="2" maxlength="10"
        show-word-limit :rules="[{ required: true, message: '昵称不能为空' }]" />
    </van-cell-group>
    <div style="margin: 15px">
      <van-button block type="primary" size="large" color="#3fa2f7" native-type="submit">
        确定
      </van-button>
    </div>
  </van-form>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import Cookies from 'js-cookie';

export default {
  setup() {
    const router = useRouter();
    const userNickname = ref("");
    //window.sessionStorage.setItem("nickname", "");
    Cookies.remove("nickname");
    let rTokenId = 0;

    // Get the token id from url
    if (JSON.stringify(router.currentRoute._value.query) != "{}") {
      rTokenId = router.currentRoute._value.query.tokenid;
      console.log("Got the Token:", rTokenId);
    }

    // 提交进行账号登录验证
    const onSubmit = async (values) => {
      // console.log("submit", userNickname.value);
      // 保存输入的nickname
      //window.sessionStorage.setItem("nickname", userNickname.value);
      Cookies.set("nickname", userNickname.value,{ expires: 30 });

      // 跳转到下一页
      router.push({ name: "choice", query: { tokenid: rTokenId } });
      console.log("Setting of the Nickname is Successed.");
    };

    return {
      userNickname,
      onSubmit,
    };
  },
};
</script>

<style scoped>
p {
  padding: 20px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: justify;
  color: #000000;
  letter-spacing: -0.08px;
}

h3 {
  height: 15px;
  font-size: 15px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  color: #297fca;
  line-height: 7px;
  letter-spacing: -0.08px;
}
</style>