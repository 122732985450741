<template>
  <div style="height:15px;background-color: #297fca;"></div>
  <div id="mine">
    <van-nav-bar :title="titleStr" left-arrow @click-left="onClickLeft" />
  </div>
  <div style="background-color: #e4f1fd;padding: 1%;">
    <p>每匹马的背后是AI聊天机器人，你可以通过投喂MB和马进行聊天，每次聊天须投喂2MB。 </p>
    <p>你的MB数量：{{ totalMB }}</p>
  </div>
  <div style="padding: 1%;">
    <p>赚取MB的核心方法是：把你的领养链接分享给更多的朋友。每天完成首次分享，会获得10MB；你的朋友每次领养成功，你会额外获得100MB。</p>
  </div>
  <div style="margin-left: 15px;margin-right: 15px;margin-top: 50px">
    <van-button block type="primary" size="large" color="#3fa2f7" to="/toshare">
      马上分享
    </van-button>
  </div>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onBeforeMount, onBeforeUnmount, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";
import Cookies from 'js-cookie';

export default {
  setup() {
    const router = useRouter();
    //const userId = window.sessionStorage.getItem("userId");
    //const nickname = window.sessionStorage.getItem("nickname");
    //const tokenId = window.sessionStorage.getItem("tokenId");
    const nickname = Cookies.get("nickname");
    const tokenId = Cookies.get("tokenId");
    const userId = Cookies.get("userId");
    const totalMB = ref(0);

    const titleStr = ref(nickname + "，#" + tokenId);

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const onClickLeft = () => history.back();

    // 获取MB信息
    const getMBInfo = async () => {
      try {
        let result = await postRequest(apiUrl.getMBInfo, {
          userId: userId,
          tokenId: tokenId
        });
        // 处理返回结果
        if (result.data.code == 200) {
          const data = result.data.data;
          // console.log(result.data);
          totalMB.value = data.mb;
        } else {
          // 打印出错信息
          console.log(result.data.msg);
          // 跳转到login，重新走login流程
          Dialog.alert({
            title: "马上友",
            message: result.data.msg,
          }).then(() => {
            // to Login
            router.push({ name: "login" });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async () => {
      await getMBInfo();
    });

    return {
      titleStr,
      totalMB,
      onClickLeft,
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
  height: 55px;
}

#mine :deep(.van-nav-bar__content) {
  height: 55px !important;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 22px;
}

#mine :deep(.van-nav-bar__right) {
  color: white !important;
  font-size: 18px;
}

#mine :deep(.van-nav-bar__arrow) {
  color: white !important;
  font-size: 22px;
}

.van-action-bar {
  background-color: transparent;
}

.van-button--large {
  height: 45px;
}

p {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: left;
  color: #297fca;
  letter-spacing: -0.08px;
  word-wrap: break-word;
}

#footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #297fca;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.14px;
}
</style>