<template>
  <div style="margin-top: 50px; margin-bottom: 10px">
    <van-image width="300" height="300" fit="cover" :src="tokenThumb" />
  </div>
  <div>
    <p>哈，你终于点进来了，真是识货之人！ </p>
    <div v-if="recommend">
      <div v-if="rectype">
        <p>我是「{{ nickname }}」，</p>
        <p>这是我参与设计的马，</p>
      </div>
      <div v-else>
        <p>我是「{{ nickname }}」，</p>
        <p>这是我领养的马，看上去还不错吧，</p>
      </div>
    </div>
    <div v-else>
      <p>虽然我不知道引荐你的是谁，</p>
      <p>但，这是我参与设计的马，</p>
    </div>
    <p>你可以从3匹中挑1匹喜欢的领养。</p>
    <p>（每个手机号仅限领养一匹）</p>
  </div>
  <div style="margin-top:30px;">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field name="token" autosize :model-value="tokenStr" readonly style="font-size: 16px;" />
      </van-cell-group>
      <div style="margin: 15px">
        <van-button block type="primary" size="large" color="#3fa2f7" native-type="submit">
          马上领养
        </van-button>
      </div>
    </van-form>
  </div>
  <!--<div id="footer">元创像素 ©2023 版权所有</div>-->
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog, Toast } from "vant";

export default {
  setup() {
    const router = useRouter();
    const image = require('../assets/mashowu.png');
    const tokenThumb = ref(image);
    let token = 0;
    const tokenStr = ref("#" + token);
    const recommend = ref(false);
    const rectype = ref(false);
    const nickname = ref("");

    onMounted(async () => {
      if (JSON.stringify(router.currentRoute._value.query) != "{}") {
        token = router.currentRoute._value.query.tokenid;
        //console.log(token)
        /*if (token >= 1 && token < 10000) {
          console.log("Get the correct tokenid.");
        } else {
          token = 0;
          console.log("Recommend token error.");
        }*/
      } else {
        console.log("No recommend token.");
      }

      let result = await postRequest(apiUrl.getShareInTokenInfo, {
        tokenId: token
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        token = data.tokenid;
        tokenStr.value = "#" + token;
        tokenThumb.value = "https://" + data.thumburl;
        // 判断是否确认是该id推荐，否则是系统自动推荐
        if (data.recommend) {
          recommend.value = true;
          nickname.value = data.nickname;
          if (token >= 1 && token < 10000) rectype.value = true;
        }
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          //router.push({ name: "login" });
        });
      }
    })

    // 开始领养流程
    const onSubmit = async (values) => {
      // 跳转到下一页
      router.push({ name: "nickname", query: { tokenid: token } });
      // console.log("Share IN is Successed.");
    };

    return {
      tokenThumb,
      tokenStr,
      recommend,
      nickname,
      rectype,
      onSubmit,
    };
  },
};
</script>

<style scoped>
p {
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: center;
  color: #000000;
  letter-spacing: -0.08px;
  height: 10px;
}
</style>