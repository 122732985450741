<template>
  <div style="height:15px;background-color: #297fca;"></div>
  <div id="mine">
    <van-nav-bar :title="titleStr" left-arrow @click-left="onClickLeft" />
  </div>
  <div style="margin-top: 40px">
    <van-image width="300px" height="300px" fit="cover" :src="tokenThumb" />
  </div>
  <div style="padding-left: 30px; text-align: left;">
    <p>我是「{{ nickName }}」，</p>
    <p>这是我稀有度高达{{ tokenRarity }}的马。 </p>
    <p>快来参与领养， </p>
    <p>看看你能拿到什么稀有度的马， </p>
    <p>别忘了说是我推荐的哦。</p>
  </div>
  <div style="margin-left: 15px;margin-right: 15px;margin-top: 30px">
    <van-button class="btn" :data-clipboard-text="message" block
      type="primary" size="large" color="#3fa2f7" native-type="submit" @click="onClick">
      马上分享
    </van-button>
  </div>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "@/utils/api.url";
import { Dialog } from "vant";
import ClipboardJS from 'clipboard';
import Cookies from "js-cookie";

export default {
  setup() {
    const router = useRouter();
    //const nickname = window.sessionStorage.getItem("nickname");
    //const tokenId = window.sessionStorage.getItem("tokenId");
    //const userId = window.sessionStorage.getItem("userId");
    const nickname = Cookies.get("nickname");
    const tokenId = Cookies.get("tokenId");
    const userId = Cookies.get("userId");
    const image = require('../assets/mashowu.png');
    const tokenThumb = ref(image);
    const tokenRarity = ref(0);
    const nickName = ref(nickname);

    const titleStr = ref(nickname + "，#" + tokenId);

    const message = "https://mashowu.pixeln.cn/#/sharein?tokenid=" + tokenId;
    // const message = "https://tma.pixeln.cn/#/sharein?tokenid=" + tokenId;

    onBeforeMount((event) => {
      document.querySelector('body').setAttribute('style', 'background-color:#FFFFFF');
    })

    onBeforeUnmount((event) => {
      document.querySelector('body').removeAttribute('style')
    })

    const handleCopy = () => {
      const clipboard = new ClipboardJS('.btn');
      clipboard.on('success', function (e) {
        /*
        console.info('Action:', e.action);
        console.info('Text:', e.text);
        console.info('Trigger:', e.trigger);*/
        e.clearSelection();
      });

      clipboard.on('error', function (e) {
        console.error('Action:', e.action);
        console.error('Trigger:', e.trigger);
      });
    }

    const onClick = (async () => {
      let result = await postRequest(apiUrl.setShareLogs, {
        userId: userId,
        shareType: "link"
      })
      // 处理返回结果
      if (result.data.code != 200) {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      } else {
        Dialog.alert({
          title: "马上友",
          message: "分享链接已经复制到剪贴板",
        }).then(() => {
          // Do nothing
        });
      }
    })

    const onClickLeft = () => history.back();

    onMounted(async () => {
      // 初始化复制剪贴板
      handleCopy();
      let result = await postRequest(apiUrl.getTokenInfo, {
        userId: userId,
        tokenId: tokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokenThumb.value = "https://" + data.thumburl;
        tokenRarity.value = data.rarity;
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    })

    return {
      nickName,
      titleStr,
      tokenThumb,
      tokenRarity,
      message,
      onClick,
      onClickLeft,
    };
  },
};
</script>

<style lang="less" scoped>
#mine {
  width: 100%;
  height: 100%;
}

.van-nav-bar {
  background-color: #297fca;
  height: 55px;
}

#mine :deep(.van-nav-bar__content) {
  height: 55px !important;
}

#mine :deep(.van-nav-bar__title) {
  color: white !important;
  font-size: 22px;
}

#mine :deep(.van-nav-bar__right) {
  color: white !important;
  font-size: 18px;
}

#mine :deep(.van-nav-bar__arrow) {
  color: white !important;
  font-size: 22px;
}

#footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #297fca;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.14px;
}

p {
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 400;
  text-align: center;
  color: #000000;
  letter-spacing: -0.14px;
  height: 8px;
  line-height: 8px;
}
</style>