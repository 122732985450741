<template>
  <div style="margin-top: 20px; height: 33px; margin-bottom: 30px;">
    <p>点击小图选择领养</p>
  </div>
  <div style="margin: 15px">
    <van-image width="350px" height="350px" fit="cover" :src="defaultIMG" />
  </div>
  <div>
    <van-form @submit="onSubmit">
      <div>
        <van-space :size="20">
          <van-image v-bind:class="{ selected: isSelected1 }" width="110px" height="110px" fit="cover" :src="imagesA1"
            @click="onClick1" />
          <van-image v-bind:class="{ selected: isSelected2 }" width="110px" height="110px" fit="cover" :src="imagesA2"
            @click="onClick2" />
          <van-image v-bind:class="{ selected: isSelected3 }" width="110px" height="110px" fit="cover" :src="imagesA3"
            @click="onClick3" />
        </van-space>
      </div>
      <div style="margin: 15px">
        <van-button block type="primary" size="large" color="#3fa2f7" native-type="submit">
          确定
        </van-button>
      </div>
    </van-form>
  </div>
  <!--<div id="footer">技术支持：元创像素</div>-->
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { postRequest } from "@/utils/http.ajax";
import apiUrl from "../utils/api.url";
import { Dialog, Toast } from "vant";
import "vant/es/toast/style";
import Cookies from "js-cookie";

export default {
  setup() {
    const router = useRouter();
    //const userId = window.sessionStorage.getItem("userId");
    //const nickName = window.sessionStorage.getItem("nickname");
    const nickname = Cookies.get("nickname");
    const userId = Cookies.get("userId");
    const image = require('../assets/mashowu.png');
    const defaultIMG = ref(image);

    const isSelected1 = ref(false);
    const isSelected2 = ref(false);
    const isSelected3 = ref(false);

    const imagesA1 = ref(image);
    const imagesA2 = ref(image);
    const imagesA3 = ref(image);

    let imagesA = [];
    let tokens = [];

    const getImageUrl = (url) => {
      return "https://" + url;
    }

    // Get the token id from url
    let rTokenId = 0;
    if (JSON.stringify(router.currentRoute._value.query) != "{}") {
      rTokenId = router.currentRoute._value.query.tokenid;
      console.log("Got the Token:", rTokenId);
    }

    onMounted(async () => {
      let result = await postRequest(apiUrl.getChoiceTokens, {
        userId: userId,
        tokenId: rTokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        tokens = data.tokens;

        for (let i = 0; i < tokens.length; i++) {
          let thumbUrl = getImageUrl(tokens[i].thumburl);
          imagesA.push(thumbUrl);
        }
        imagesA1.value = imagesA[0];
        imagesA2.value = imagesA[1];
        imagesA3.value = imagesA[2];
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    });

    const onClick1 = (Event) => {
      isSelected1.value = !isSelected1.value;
      if (isSelected1.value) {
        isSelected2.value = false;
        isSelected3.value = false;
        defaultIMG.value = imagesA[0];
      } else {
        defaultIMG.value = image;
      }
    };

    const onClick2 = (Event) => {
      isSelected2.value = !isSelected2.value;
      if (isSelected2.value) {
        isSelected1.value = false;
        isSelected3.value = false;
        defaultIMG.value = imagesA[1];
      } else {
        defaultIMG.value = image;
      }
    };

    const onClick3 = (Event) => {
      isSelected3.value = !isSelected3.value;
      if (isSelected3.value) {
        isSelected1.value = false;
        isSelected2.value = false;
        defaultIMG.value = imagesA[2];
      } else {
        defaultIMG.value = image;
      }
    };

    const onSubmit = async () => {
      let selected;
      if (
        isSelected1.value == false &&
        isSelected2.value == false &&
        isSelected3.value == false
      ) {
        Toast.fail("请先选择您想要的马头，再点确定");
        return;
      }

      if (isSelected1.value == true) {
        selected = 0;
        console.log("Select:", 1);
      }
      if (isSelected2.value == true) {
        selected = 1;
        console.log("Select:", 2);
      }
      if (isSelected3.value == true) {
        selected = 2;
        console.log("Select:", 3);
      }

      console.log("Got Choice tokenId:", tokens[selected].tokenid);
      // 提交token选择
      let result = await postRequest(apiUrl.setChoiceToken, {
        userId: userId,
        nickName: nickname,
        tokenId: tokens[selected].tokenid,
        rTokenId: rTokenId
      });
      // 处理返回结果
      if (result.data.code == 200) {
        const data = result.data.data;
        // console.log(result.data);
        //window.sessionStorage.setItem("tokenId", data.tokenId);
        Cookies.set("tokenId", data.tokenId, { expires: 30 });
        router.push({ name: "success" });
      } else {
        // 打印出错信息
        console.log(result.data.msg);
        // 跳转到login，重新走login流程
        Dialog.alert({
          title: "马上友",
          message: result.data.msg,
        }).then(() => {
          // to Login
          router.push({ name: "login" });
        });
      }
    };

    return {
      defaultIMG,
      isSelected1,
      isSelected2,
      isSelected3,
      imagesA1,
      imagesA2,
      imagesA3,
      onSubmit,
      onClick1,
      onClick2,
      onClick3,
    }
  },
}
</script>

<style scoped>
p {
  padding: 20px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 600;
  text-align: center;
  color: #000000;
  letter-spacing: -0.14px;
}

.selected {
  box-shadow: 0 8px 8px 0 rgba(85, 110, 97, 0.35);
  border-radius: 5px;
  border: 1px solid grey;
  width: 120px;
  height: 120px;
}
</style>